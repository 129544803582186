import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { ApiService } from './services/api.service';
import toastify from './plugins/toastify';
import KeyCloakService from "./security/KeycloakService";
import HttpService from "./services/HttpService";

// Function to create and configure the Vue app instance
async function initializeApp() {
  await KeyCloakService.CallLogin(); // Wait for Keycloak login before creating the app

  // Create the app instance
  const app = createApp(App);

  app.config.globalProperties.$apiService = ApiService;

  app.use(router);
  app.use(store);
  app.use(vuetify);
  app.use(toastify, {
    autoClose: 3000,
    position: 'top-right',
    limit: 3
  });

  app.mount('#app');

  // Configure Axios with Keycloak token after app is mounted
  HttpService.configureAxiosKeycloak();
}

// Call the function to initialize and mount the app
initializeApp();
