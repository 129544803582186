import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '../components/DashboardView.vue';
import KeyCloakService from "./../security/KeycloakService";

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView
  },
  {
    path: '/openvouchers',
    name: 'openvouchers',
    component: DashboardView
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

//  This is a guard
router.beforeEach((to: any, from: any, next: any) => {
  if (KeyCloakService.IsLoggedIn()) {
    if (to.path === '/') {
      next();
    } else {
      next();
    }
  } else {
    //@ts-ignore
    const loginUrl = router.app.$keycloak.createLoginUrl({
      redirectUri: window.location.origin + to.fullPath,
    });
    window.location.replace(loginUrl);
  }
});
