import axios, {AxiosPromise, AxiosRequestConfig, AxiosResponse} from 'axios';

import router from './../router/index';

export const ApiService = {

  setupInterceptor() {
    axios.interceptors.response.use(this.handleSuccess, this.handleError);
  },

  handleSuccess(response: any) {
    return response;
  },

  handleError(error: any) {
    if (!error.response) {
      const error = {
        response: {
          data: "Netzwerkfehler"
        }
      }
      return Promise.reject(error)
    }
    if (error.response.status == 401) {
      router.push("/");
    } else {
      return Promise.reject(error)
    }
  },

  get(url: string, token: any): Promise<AxiosResponse> {
    return axios.get(url, {
      headers: token,
    });
  },

  gets(url: string, token: any): Promise<AxiosResponse> {
    return axios.get(url, {
      responseType: 'blob',
      headers: token,
    });
  },

  post(url: string, data: { [key: string]: any }, token: any): Promise<AxiosResponse> {
    return axios.post(url, data, {
      headers: token,
    });
  },

  put(url: string, data: { [key: string]: any }, token: any): Promise<AxiosResponse> {
    return axios.put(url, data, {
      headers: token,
    });
  },

  custom(config: AxiosRequestConfig): AxiosPromise<any> {
    return axios(config);
  },
};
