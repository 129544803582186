import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        justify: "start",
        align: "start"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tiles, (tile, index) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              cols: "12",
              xl: "2",
              sm: "5",
              md: "5",
              key: index
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  elevation: "3",
                  class: "pa-4 d-flex justify-center align-center",
                  style: _normalizeStyle({
            cursor: tile.disabled ? 'not-allowed' : 'pointer',
            height: '200px',
            opacity: tile.disabled ? 0.5 : 1,
          }),
                  onClick: ($event: any) => (!tile.disabled && _ctx.openInNewTab(tile.url))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "10",
                      class: "text-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, {
                          class: "headline text-center",
                          style: {"white-space":"normal"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tile.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (tile.subtitle)
                          ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                              key: 0,
                              class: "headline text-center",
                              style: {"white-space":"normal"}
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(tile.subtitle), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["style", "onClick"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}