import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import { defineComponent } from 'vue';

import { VApp, VMain, VContainer } from 'vuetify/components';
import { VBtn } from 'vuetify/components';

export default createVuetify({
  components: {
    VApp,
    VMain,
    VContainer,
    VBtn,
  },
  theme: {
    defaultTheme: 'light',
  },
});
