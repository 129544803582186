<template>
  <v-app v-if="!$route.meta.layoutPlain">    
    <v-app-bar
      app
      clipped-left
      color="black"
      dense
    >
      <v-toolbar-title class="mr-12 align-center">
        <div style="display: flex; align-items: center !important;">
          <img style="max-width: 2.5rem !important;" src="./assets/amjlogo.png">
          <span style="margin-left: 10px; color: white; font-weight: 500;" class="title">
          AMJ Deutschland Portal  <span style="color: orange; border: 2px solid; padding: 5px;" v-if="testEnv"> TEST </span>
        </span>

        </div>


      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout" to="/" style="border-radius: 0px" depressed>
        Logout {{username}}
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>


  <v-app v-else-if="$route.meta.layoutPlain">
    <router-view></router-view>
  </v-app>
</template>


<script lang="ts">
  import {Watch} from "vue-property-decorator";
  import { Options, setup, Vue } from 'vue-class-component';
  import router from './router';
  import {Getter, Mutation} from "vuex-class";
  import KeyCloakService from "./security/KeycloakService";
  import { useStore } from 'vuex';

  @Options({
    components: {},
  })
  export default class App extends Vue {

    private items: any[] = [];
    private username: string | undefined = "";

    private testEnv = false;

    store = useStore();

    logout() {
      KeyCloakService.CallLogOut();
    }

    created() {
      if (process.env.VUE_APP_ENV === 'test') {
        this.testEnv = true;
      }
      this.username = KeyCloakService.GetUserName();
    }

  }
</script>

<style>
</style>
